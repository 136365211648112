<template>
  <div class="bc-float-end">
    <div class="bc-d-flex bc-align-items-center bc-text-primary" style="height: 2rem">
      <b-link 
        :to="itemTypeRoute" 
        class="bc-ms-1"
      >
        <b-badge
          v-b-tooltip.hover
          pill
          variant="primary"
          class="bc-badge bc-bg-primary bc-text-secondary bc-text-capitalize bc-mb-0 bc-ms-1 bc-mt-2"
        >
          {{ isFullScreen ? $t('common.full-screen') : $t('common.view-all') }}
        </b-badge>
      </b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewAllButton',
  props: {
    itemType: { type: String, required: true },
    type: { type: String },
    isFullScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
    itemTypeRoute() {
      if (this.itemType === 'community-members') {
        return { name: this.itemType, params: { type: this.type } };
      }
      if (this.itemType === 'entity') {
        return { name: this.itemType, params: { typeKey: this.type } };
      }
      if (this.itemType === 'people') {
        return { name: this.itemType, params: { typeKey: this.type } };
      }
      if (this.itemType === 'events' && this.type) {
        return { name: this.itemType, query: { display: this.type } };
      }
      if (this.itemType === 'challenges' && this.type) {
        return { name: this.itemType, query: { display: this.type } };
      }
      return { name: this.itemType };
    },
  },
  methods: {
    location() {
      return this.$router.push({ name: this.itemType });
    },
  },
};
</script>

<style lang="scss" scoped>
.bc-badge {
  &.bc-bg-primary {
    background-color: var(--primary) !important;
    color: var(--secondary) !important;
    transition: opacity 0.2s ease;
    
    &:hover {
      opacity: 0.85 !important;
    }
  }
}

#search-tooltip {
  cursor: pointer;
}

.create-item {
  cursor: pointer;
}
</style>
